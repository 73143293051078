import { motion } from "framer-motion";
import GatsbyImage from "gatsby-image";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";
import React from "react";
import useIntersectionObserver from "../loaders/useIntersectionObserver";
import { css } from "@emotion/core";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
// import SEO from "../components/seo"
import SEO from "../components/seo";
import QuoteMark from "../images/quote-mark.png";
import tw from "tailwind.macro";
import { graphql, Link, useStaticQuery } from "gatsby";
import ReactPlayer from "react-player";
export default function DogArticle({ article }) {
  const { frontmatter, html } = article;
  const titleRef = React.useRef();
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          url
        }
      }
    }
  `);
  useIntersectionObserver({
    target: titleRef,
    threshold: 1,
    onIntersect: () => {
      typeof window !== "undefined" &&
        window.history.replaceState(
          {},
          frontmatter.title,
          "/" + frontmatter.slug
        );
    },
    rootMargin: "0px 0px -70% 0px",
  });
  
  return (
    
    <>
    {/* <SEO title="Category page" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Category page"
      keywords={[`great`, `british`, `dogs`]}
    />
      <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        className="story container max-w-2xl py-12"
        css={css`
          &.story:last-of-type + hr {
            display: none;
          }
        `}
      >
        <div className="space-x-4 flex items-center justify-center">
          {frontmatter.tags.map((tag) => (
              <Link
                to={"/" + kebabCase(tag)}
                className="bg-article uppercase leading-none px-3 py-1 mb-2 rounded-lg text-white font-bench"
                key={tag}
              >
                {tag}
              </Link>
            ))}
        </div>
        <h1
          ref={titleRef}
          className="text-primary text-center text-3xl font-slab font-bold mb-1"
        >
          {frontmatter.title}
        </h1> 
        <h2
          className="prose text-center text-lg font-slab mb-5"
        >
          {frontmatter.intro}
        </h2> 
        <div className="my-6">
          {get(frontmatter, "video") && (
            <div
              className="py-2"
              css={css`
                /* purgecss start ignore */
                .embedVideo-container {
                  text-align: center;
                  max-width: 650px;
                  // margin-left: 200px;

                  /* @lg */
                  @media (max-width: 980px) {
                    max-width: 90vw;
                    margin-left: 0px;
                  }
                }

                .embedVideo-iframe {
                  max-width: 720px;
                  max-height: 50vw;
                  @media (max-width: 980px) {
                    max-width: 90vw;
                  }
                }
                /* purgecss end ignore */
              `}
            >
              <ReactPlayer muted autoplay url={frontmatter.video} />
            </div>
          )}

          {get(frontmatter, "childImageSharp.fluid") && (
            <div
              className={`my-2 ${
                frontmatter.dom
                  ? "border-8 border-accent"
                  : " " && frontmatter.doy
                  ? "border-8 border-accent"
                  : " "
              }`}
            >
              <GatsbyImage
                fluid={get(frontmatter, "childImageSharp.fluid")}
              />
            </div>
          )}
        </div>
        <div
          className="prose mx-auto max-w-none"
          css={css`
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #999696;
            }
            & blockquote {
              ${tw`border-none not-italic max-w-md mx-auto p-0 font-slab text-center text-primary text-2xl`}
              p:first-of-type {
                strong {
                  ${tw`text-primary`}
                }
                &::before {
                  content: "";
                  ${tw`mx-auto mb-5 block `}
                  width: 25px;
                  height: 20px;
                  background: url(${QuoteMark});
                  background-repeat: no-repeat;
                }
                &:after {
                  content: "";
                  width: 100px;
                  height: 6px;
                  border-radius: 3px;
                  ${tw`block bg-primary mx-auto mt-5`}
                }
              }
            }
          `}
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
        <div className="flex items-center justify-center space-x-4 my-12">
          <a
            href={`https://twitter.com/intent/tweet?url=${encodeURI(
              site.siteMetadata.url + frontmatter.slug
            )}&text=${encodeURI(frontmatter.title)}&hashtags=greatbritishdogs`}
            target="_blank"
            className="p-2 block bg-primary text-white   rounded-full cursor-pointer"
          >
            <FaTwitter className="  w-4 h-4 " />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
              site.siteMetadata.url + frontmatter.slug
            )}`}
            target={"_blank"}
            className="p-2 bg-primary text-white   rounded-full cursor-pointer"
          >
            <FaFacebookF className="  w-4 h-4 " />
          </a>
        </div>
      </motion.div>
      <hr />
    </>
  );
}
