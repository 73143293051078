import GatsbyImage from "gatsby-image";
import { get, has } from "lodash";
import React, { useRef, useState } from "react";
import Footer from "../components/Layout/Footer";
import Navbar from "../components/Layout/Navbar";
import TopBar from "../components/Layout/TopBar";
import Seo from "../components/seo";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import QuoteMark from "../images/quote-mark.png";
import useIntersectionObserver from "../loaders/useIntersectionObserver";
import shuffle from "lodash/shuffle";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import ReusableTiles from "../components/ReusableTiles";
import FourthReusableTiles from "../components/FourthReusableTiles";
import SecondReusableTiles from "../components/SecondReusableTiles";
import SmallReusableTiles from "../components/SmallReusableTiles";
import SeniorReusableTiles from "../components/SeniorReusableTiles";
import PuppyReusableTiles from "../components/PuppyReusableTiles";
import AdultReusableTiles from "../components/AdultReusableTiles";
import FeedingGreatness2024 from "../components/FeedingGreatness2024";
import ParallaxEverydayHero from "../components/ParallaxEverydayHero";
import DogStory from "../components/DogStory";
import DogArticle from "../components/DogArticle";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import Navigation from "../components/Layout/Navigation";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import { graphql, Link } from "gatsby";
import ShareYourStoryButton from "../components/ShareYourStoryButton";
import { InView } from "react-intersection-observer";
export default function Article({ data, location }) {
  const filterTypes = get(location, "state.filterType");
  const filter = get(location, "state.filter");
  const list = React.useMemo(
    () =>
      shuffle(
        data.articles.nodes.filter((article) => {
          if (!filterTypes) {
            return true;
          }

          if (typeof article.frontmatter[filterTypes] == "string") {
            return (
              article.frontmatter[filterTypes] &&
              article.frontmatter[filterTypes] === filter
            );
          } else if (typeof article.frontmatter[filterTypes] == "number") {
            return (
              has(article, "frontmatter.year") &&
              article.frontmatter.year > 2019
            );
          } else if (typeof article.frontmatter[filterTypes] == "object") {
            return (
              article.frontmatter[filterTypes] &&
              article.frontmatter[filterTypes].includes(filter)
            );
          } else {
            return true;
          }
        })
      ),
    [filterTypes, filter]
  );

  const [show, setShow] = useState(5);
  const [cta, setCta] = useState(false);

  const showCta = () => {
    setCta(true);
    // if (typeof window !== "undefined" && window.pageYOffset > 500) {
    //   setCta(true);
    // }
  };
  const hideCta = () => {
    setCta(false);
  };

  return (
    <>
      <Seo></Seo>
      <TopBar></TopBar>{" "}
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />
      <AnimateSharedLayout>
        {" "}
        <motion.div layout>
          <AnimatePresence initial={false}>
            <DogArticle article={data.article}></DogArticle>
          <div className="bg-pale-100">
            <ReusableTiles/>
          </div>

            {list &&
              list.slice(0, show).map((node, index) => (
                <React.Fragment key={index}>
 
                  {/* First show after 2 article then after every 12 stories*/}
                  {(index - 1) % 12 == 0 && (
                    <motion.div
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 1 }}
                    >
                      <Trigger onIntersect={hideCta} onLeave={showCta} />
                      <div className="bg-pale-100">
                      <PuppyReusableTiles/>
                      </div>
                    </motion.div>
                  )}

                  { (index - 2) % 12 == 0 && (
                    <motion.div
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 1 }}
                    >
                      <Trigger onIntersect={hideCta} onLeave={showCta} />
                      <div className="bg-pale-100">
                      <SmallReusableTiles/>
                      </div>
                    </motion.div>
                  )}


                { (index - 3) % 12 == 0 && (
                    <motion.div
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 1 }}
                    >
                      <Trigger onIntersect={hideCta} onLeave={showCta} />
                      <div className="bg-pale-100">
                      <SeniorReusableTiles/>
                      </div>
                    </motion.div>
                  )}


                { (index - 4) % 12 == 0 && (
                    <motion.div
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 1 }}
                    >
                      <Trigger onIntersect={hideCta} onLeave={showCta} />
                      <div className="bg-pale-100">
                      <AdultReusableTiles/>
                      </div>
                      
                    </motion.div>
                  )}
                  <DogArticle key={node.frontmatter.slug} article={node} />
                </React.Fragment>
              ))}
          </AnimatePresence>
        </motion.div>
      </AnimateSharedLayout>
      <ShareYourStoryButton show={cta} />
      <Trigger onIntersect={hideCta} onLeave={showCta} />
      <InView
        rootMargin="300px 0px 300px 0px"
        as="div"
        onChange={(inView, entry) => {
          if (inView) {
            setShow(show + 5);
          }
        }}
      ></InView>
      <Footer></Footer>
    </>
  );
}

const Trigger = ({ onIntersect, onLeave }) => {
  const ref = useRef();
  useIntersectionObserver({
    target: ref,
    rootMargin: "100px 0px 100px 0px",
    threshold: 0,
    onIntersect,
    onLeave,
  });

  return <div ref={ref}></div>;
};
const End = ({ onIntersect, onLeave }) => {
  const ref = useRef();
  useIntersectionObserver({
    target: ref,
    rootMargin: "100px 0px 300px 0px",
    threshold: 0,
    onIntersect,
    onLeave,
  });

  return <div className="h-4" ref={ref}></div>;
};

export const query = graphql`
  query Article($slug: ID) {
    articles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/src/articles/" }
        frontmatter: { slug: { ne: $slug } }
      }
    ) {
      nodes {
        frontmatter {
          title
          tags
          slug
          dom
          doy
          location
          year
          intro
          video
          thumb {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }
    article: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tags
        dom
        doy
        slug
        location
        year
        intro
        video
        thumb {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`;
